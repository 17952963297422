<template>
  <!-- <div class="nav-header" :class="{ is_fixed: isFixed }"> -->
  <div class="nav-header">
    <div class="container">
      <!-- logo -->
      <div class="n-logo">
        <!-- <a href="/"> <img src="/imgs/logo.png" alt="" /></a> -->

        <a href="/">画娱网</a>
      </div>
      <!-- 导航 -->
      <div class="n-list">
        <a href="/">网站首页</a>
        <a href="/#/ac1">行业资讯</a>
        <!-- <a href="/#/ac2">健康饮食</a> -->
        <!-- <a href="/#/ac3">美容护肤</a> -->
        <a href="/#/info">关于我们</a>
        <a href="javascript:;" @click="goLogin()" v-show="this.LoggedShow">登录/注册</a>
        <!-- 用户名 -->
        <a
          href="javascript:;"
          v-show="!this.LoggedShow"
          v-text="this.nv_user.Username"
        ></a>
        <a href="javascript:;" @click="tolg()" v-show="!this.LoggedShow">退出登录</a>
      </div>

      <!-- 联系方式 -->
      <!-- <div class="n-phone">
        <p><i class="iconfont icon-weibiaoti-"></i> 13811803245</p>
        <p><i class="iconfont icon-youxiang"></i>09siyu@163.com</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 是否吸顶
      isFixed: false,
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
    // 绑定事件
    window.addEventListener("scroll", this.initHeight);
  },
  methods: {
    // 吸顶
    initHeight() {
      // 滚动高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      this.isFixed = scrollTop > 80 ? true : false;
    },
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      if (this.k) {
        window.localStorage.setItem("state", "10");
        window.localStorage.removeItem("k");
        this.$router.push("/");
        location.reload();
      } else {
        window.localStorage.setItem("state", "10");
        this.$router.push("/");
        location.reload();
      }

      // this.LoggedShow = true;

      // this.$forceUpdate()
    },
    // 相关资讯跳转
    goAc() {
      this.$router.push("/ac");
    },
    // 关于我们跳转
    goInfo() {
      this.$router.push("/info");
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.initHeight, false);
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-header {
  width: 100%;
  background-color: #fafafa;
  height: 90px;
  // 吸顶样式
  &.is_fixed {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 111;
    border-bottom: 1px solid $colorZ;
    .container {
      .n-logo {
      
      
      }
      .n-list {
        a {
          color: #333;
          &:hover {
            color: $colorZ;
          }
        }
      }

      .n-login {
        a {
          color: #333;
          &:hover {
            color: $colorZ;
          }
        }
      }
    }
  }

  .container {
    height: 100%;
    @include flex();
    // logo
    .n-logo {
      // width: 300px;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        // height: 100%;
      }
      a {
        color: $colorZ;
        font-size: 21px;
        font-weight: 400;
        
      }
    }
    // 导航
    .n-list {
      a {
        color: #444;
        font-size: 14px;
        margin: 0 15px;
        &:hover {
          color: $colorZ;
        }
      }
    }

    // 联系方式
    .n-phone {
      p {
        font-size: 14px;
        color: #444;
        text-align: left;
        margin: 10px 0;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
          color: $colorZ;
        }
        i {
          font-size: 16px;
          font-weight: bold;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
