<template>
  <div class="nav-footer">
    <div class="container">
      <!-- 右侧 -->
      <div class="r">
        <p>
          <span>公司邮箱：quwanfwb@52tt.com</span>
        </p>
        <p>
          <span>公司电话：020-81450999</span>
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >备案号：京ICP备2021035661号-3</a
          >
        </p>
      </div>
      <!-- 左侧 -->
      <div class="l">
        <p>公司名称： 北京画娱天下科技有限公司</p>
        <p>联系地址：北京市海淀区知春路6号(锦秋国际大厦)4层A03</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  height: 95px;
  background: #444;
  // margin-top: 30px;
  .container {
    height: 100%;
    @include flex();
    .l {
      p {
        font-size: 12px;
        color: #fafafa;
        margin: 15px 0;
        text-align: right;
      }
    }
    .r {
      text-align: left;
      p {
        font-size: 12px;
        color: #fafafa;
        margin: 15px 0;
        i {
          margin: 0 5px;
        }
        a {
          color: #fafafa;
        }
      }
    }
  }
}
</style>
