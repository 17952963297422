<template>
  <div class="index">
    <!-- 导航栏 -->
    <!-- <nav-header :href="this.href"></nav-header> -->
    <div class="container">
      <!-- 轮播 st -->
      <div class="swiper-box">
        <!-- <swiper :options="swiperOption">
         
          <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
            <a href="javascript:;"><img :src="item.img" /></a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper> -->
        <img src="/imgs/ct.jpg" alt="">
      </div>
      <!-- 轮播 end -->

      <!-- 产品服务 st -->
      <div class="product">
        <!-- 标题 -->
        <div class="title">
          <h2>产品服务</h2>
          <h4>PRODUCTS SERVICES</h4>
        </div>
        <!-- 容器 -->
        <div class="product-con">
          <div class="product-con">
            <!-- 列表1 -->
            <div class="product-item">
              <!-- 图标 -->
              <!-- <i class="iconfont icon-taocixingye"></i> -->
              <img
                src="http://www.cytdd.com/images/psIco-01.png"
                alt=""
                style="width: 130px"
              />
              <p>
                十年最基础的前端开发经验，让最简单的知识变得细腻，让最基础的标签显得灵动。
              </p>
            </div>
            <!-- 列表2 -->
            <div class="product-item">
              <!-- 图标 -->
              <!-- <i class="iconfont icon-taoci"></i> -->
              <img
                src="http://www.cytdd.com/images/psIco-02.png"
                alt=""
                style="width: 130px"
              />
              <p>写好每一行代码，做好每张设计图的解析，构建最理想的布局思路。</p>
            </div>
            <!-- 列表3 -->
            <div class="product-item">
              <!-- 图标 -->
              <!-- <i class="iconfont icon-yishupin"></i> -->
              <img
                src="http://www.cytdd.com/images/psIco-03.png"
                alt=""
                style="width: 130px"
              />
              <p>为客户提供完美的服务</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品服务 end -->

      <!-- 资讯区块 st -->
      <div class="ac-con">
        <!-- 标题 -->
        <div class="title">
          <h2>行业资讯</h2>
          <h4>real-time info</h4>
        </div>
        <!-- 内容区块 -->
        <div class="ac-list">
          <!-- 循环项 -->
          <div
            class="ac-item"
            v-for="(item, index) in this.ac1"
            :key="index"
            @click="gopay()"
          >
            <!-- 资讯图片 -->
            <div class="ac-img">
              <img :src="item.img" alt="" />
            </div>
            <!-- 资讯标题 -->
            <div class="ac-title">
              <!-- 标题名称 -->
              <div class="ac-name">{{ item.title }}</div>
              <!-- 作者 -->
              <div class="ac-author">{{ item.author }}</div>
            </div>
          </div>
        </div>

        <!-- 了解更多 -->
        <a href="/#/ac" class="ac-btn"> 了解更多 > </a>
      </div>
      <!-- 资讯区块 end -->
    </div>

    <!-- 关于我们 st -->
    <div class="info-con">
      <div class="container">
        <!-- 标题 -->
        <div class="info-title">
          <h2>关于我们</h2>
          <h4>ABOUT US</h4>
        </div>

        <!-- 文字描述 -->
        <div class="info-txt">
          <div class="p-con">
            <p>
               北京画娱天下科技有限公司成立于2015年11月19日，经营范围包括技术开发、技术转让、技术咨询、技术服务；组织文化艺术交流活动（不含营业性演出）；文艺创作；网络文化经营；第二类增值电信业务。（市场主体依法自主选择经营项目，开展经营活动；第二类增值电信业务、网络文化经营以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
            </p>
          </div>
        </div>

        <!-- banner 图片 -->
        <div class="info-banner"></div>

        <!-- 了解更多 -->
        <a href="/#/info" class="info-btn"> 了解更多 > </a>
      </div>
    </div>
    <!-- 关于我们 end -->
     <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="width: 275px" src="/imgs/pay.png" />
        <h2>扫码收费：1元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
// import NavHeader from './../components/NavHeader'
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
import Modal from "./../components/Modal.vue";
export default {
  name: "index",
  data() {
    return {
      href: "/#/index",
      showModal: false,
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "cube",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/swiper1.jpg",
        },
        {
          id: "2",
          img: "/imgs/swiper2.jpg",
        },
       
      ],
      // 资讯1 数据
      ac1: [],
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  components: {
    // NavHeader,
    // Swiper,
    // SwiperSlide,
    Modal
  },
  mounted() {
    document.title = "画娱网";
    this.init();
  },
  methods: {
    // 初始化 json
    init() {
      this.axios.get("/mock/ac.json").then((res) => {
        const data = res.data;
        // 资讯1 数据
        this.ac1 = data.slice(0, 6);
      });
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
     alert("资讯每条1元，购买后观看！");
      this.showModal = true;
      // if (this.state == 0) {
      //   // alert('资讯每条3元，点击确定注册购买')
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每条1元，点击确定注册购买");
      //   this.$router.push({ path: '/login', query: { k: 1 } })
      //   // this.$router.push("/pay");
      //   return;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  .container {
    // 轮播图
    .swiper-box {
      margin: 60px auto;
      height: 450px;
      img {
        width: 100%;
        height: 100%;
        background-color: pink;
      }
      // 循环项
      .swiper-container {
        height: 451px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    // 资讯区块
    .ac-con {
      // 标题
      .title {
        text-align: center;
        h2 {
          font-size: 32px;
          font-weight: bold;
          color: #666;
        }
        h4 {
          font-size: 26px;
          margin: 15px auto;
          color: #666;
        }
      }
      // 列表容器
      .ac-list {
        margin: 30px auto;
        width: 100%;
        // height: 300px;
        // background-color: chartreuse;
        @include flex();
        flex-wrap: wrap;
        // 循环项
        .ac-item {
          width: 30%;
          height: 90%;
          // background-color: #fff;
          margin: 0 15px;
          // 资讯图片
          .ac-img {
            width: 100%;
            height: 230px;
            // background-color: red;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          // 资讯标题
          .ac-title {
            height: 40px;
            @include flex();
            font-size: 16px;
            color: #666;
            font-weight: bold;
            cursor: pointer;
            // 标题名称
            .ac-name {
              width: 300px;
              white-space: nowrap; //不换行
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //变成...
            }
          }
        }
      }
      // 了解更多
      .ac-btn {
        display: block;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: $colorZ;
        cursor: pointer;
      }
    }
    // 产品服务区块
    .product {
      margin: 50px 0;
      // 标题
      .title {
        text-align: center;
        h2 {
          font-size: 32px;
          font-weight: bold;
          color: #666;
        }
        h4 {
          font-size: 26px;
          margin: 15px auto;
          color: #666;
        }
      }
      // 容器
      .product-con {
        @include flex();
        margin: 20px auto;
        // height: 500px;
        // 列表
        .product-item {
          width: 300px;
          height: 300px;
          // background-color: pink;
          margin: 0 20px;
          text-align: center;
          // 图标
          i {
            font-size: 200px;
            margin: 10px auto;
          }
          // 文字
          p {
            padding-top: 20px;
            border-top: 1px solid #ccc;
            font-size: 16px;
            font-weight: bold;
            color: rgb(128, 120, 120);
            margin-top: 30px;
          }
        }
      }
    }
  }
  // 关于我们
  .info-con {
    width: 100%;
    height: 1000px;
    // background-color: pink;
    background: url("/imgs/bj.jpg") no-repeat center;
    background-size: cover;
    margin: 50px auto;
    padding: 20px 0;
    .container {
      // 标题
      .info-title {
        text-align: center;
        h2 {
          font-size: 32px;
          font-weight: bold;
          color: #fff;
        }
        h4 {
          font-size: 26px;
          margin: 15px auto;
          color: #fff;
        }
      }
      // 文章
      .info-txt {
        .p-con {
          text-align: center;
          line-height: 42px;
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          width: 800px;
          margin: 30px auto;
        }
      }
      // banner
      .info-banner {
        width: 800px;
        height: 500px;
        // background-color: #ff6600;
        background: url("/imgs/ct1.jpg") no-repeat center;
        background-size: cover;
        margin: 20px auto;
      }
      // 了解更多
      .info-btn {
        display: block;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
