<template>
  <div class="index">
    <div class="banner">
      <img src="/imgs/syzx.jpg" alt="" />
    </div>
    <div class="container">
      <!-- 主容器，分左右，右边长列表 -->
      <div class="i-con">
        <!-- 左侧长内容 -->
        <div class="i-l">
          <!-- 头部 -->
          <div class="s-header">
            <h2>{{ this.title }}</h2>
            <span>{{ this.author }}</span>
            <span>时间：{{ this.time }}</span>
            <span>{{ this.sub }}阅读</span>
          </div>

          <!-- 内容 -->
          <div class="s-con">
            <!-- 文字 -->
            <div class="s-text">
              <p>{{ this.article1 }}</p>
              <!-- 图片 -->
              <img :src="this.img" alt="" />
              <p>{{ this.article2 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "index.html",
      // 获取 id
      myId: this.$route.query.type,
      // 获取 标题
      title: "",
      // 简介
      concise: "",
      // 作者
      author: "",
      // 图片路径
      img: "",

      // 文章1
      article1: "",
      // 文章2
      article2: "",
      time: "",
      ac3: [],
      sub: "",
    };
  },

  mounted() {
    this.init();
    document.title = "北京网视在线科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/index2.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        console.log(date);
        // console.log(date)
        // 标题
        this.title = date.title;
        // 作者
        this.author = date.author;
        // 歌词
        this.concise = date.concise;
        // 图片路径
        this.img = date.img;
        // 文章 1
        this.article1 = date.p1;
        // 文章 2
        this.article2 = date.p2;
        this.time = date.time;
        this.ac3 = vl.slice(0, 6);
        this.sub = date.sub;
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条3元，点击确定注册购买");
        this.$router.push({ path: "/register", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  //   background-color: $colorZ;
  margin-top: 65px;
  .banner {
    width: 100%;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    // 主容器1
    .i-con {
      display: flex;
      justify-content: space-between;
      width: 100%;
      // height: 3000px;
      // background-color: pink;
      margin: 60px auto 60px;
      // 左侧
      .i-l {
        padding: 20px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;

        // 头部
        .s-header {
          width: 100%;
          height: 100px;
          background-color: #fff;
          text-align: center;
          border-bottom: 1px dashed #ccc;
          h2 {
            margin-bottom: 30px;
          }
          span {
            font-size: 14px;
            margin: 0 15px;
          }
        }
        // 文章容器
        .s-con {
          // 图片
          img {
            text-align: center;
            width: 70%;
            height: 400px;
            margin: 30px auto;
            display: flex;
            // background-color: pink;
          }
          // 文章
          .s-text {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            p {
              margin: 20px auto;
              font-size: 14px;
              line-height: 70px;
            }
          }
        }
      }

      // 右侧
      .i-r {
        width: 30%;
        height: 100%;
        // background-color: #c60023;
        box-sizing: border-box;

        // 推荐区块
        .z1-con {
          width: 100%;
          height: 190px;
          background-color: #fff;
          padding-left: 10px;
          box-sizing: border-box;
          // color:;
          cursor: pointer;
          span {
            border: none;
            border-radius: 0;
            color: #fff;
            display: inline-block;
            font-size: 14px;
            padding: 5px 15px;
            width: auto;
            margin: -1px 0 0 15px;
            z-index: 2;
            background-color: $colorZ;
          }
          // 标题
          .z1-title {
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 10px;
            color: $colorZ;
            margin-top: 20px;
            font-weight: bold;
          }
          // 简介
          .z1-brief {
            font-size: 14px;
            line-height: 1.7;
            color: #999;
            text-indent: 30px;
          }
        }

        // 最新文章区块
        .z2-con {
          background-color: #fff;
          border: 1px solid #eaeaea;
          // margin-bottom: 12px;
          padding: 15px;
          line-height: 1.4;
          margin: 20px auto;
          // 标题
          .z2-title {
            background-color: #fbfbfb;
            border-bottom: 1px solid #eaeaea;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            color: #333;
            font-size: 14px;
            position: relative;
            padding: 9px 15px;
            position: relative;
            top: -15px;
            left: -15px;
            width: 100%;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
          }

          // 循环项
          .z2-item {
            display: flex;
            margin: 15px auto;
            align-items: center;
            // 图片
            img {
              width: 80px;
              height: 80px;
              background-color: #c60023;
              margin-right: 15px;
            }
            // info
            .z2-info {
              .z2-t {
                font-size: 14px;
                color: #333;
                font-weight: 400;
              }
              .z2-time {
                font-size: 13px;
                margin-top: 4px;
                color: #999;
              }
            }
          }
        }
      }
    }
  }
}
</style>
